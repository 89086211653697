import React, {useState, useEffect, forwardRef, memo, useMemo, useCallback, ReactNode, useRef} from "react";
import classnames from 'classnames';
import {AgGridReact} from "ag-grid-react";
import {CellClickedEvent, CellEditRequestEvent, ColDef, ColumnApi, ColumnMovedEvent, ColumnPinnedEvent, ColumnResizedEvent, ColumnState, FilterChangedEvent, GridApi, GridReadyEvent} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.min.css"; // Move up to top of app?  eg. app.js or index.js?
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Move up to top of app?  eg. app.js or index.js?
// must import this custom CSS last
import './ByzzerTable.scss';
import {AgGridReactProps} from "ag-grid-react/lib/shared/interfaces";
import CustomHeader from "./CustomHeader";
import { ByzzerChip } from "../ByzzerChip";
import { useTenantApi } from "@/hooks";
import { useUser } from "@/contexts/UserContext";
import { debounce } from "lodash";
import { ByzzerButton } from "@byzzer/ui-components";
import { ByzzerLink } from "../form";
import { ByzColDef, TableArea } from "@/types/TableTypes";
import _ from "lodash";

const baseClassName = 'byz-table';

export const BYZZER_TABLE_THEME = 'ag-theme-alpine';

const LAYOUT_SAVE_DEBOUNCE_TIME_IN_MS = 750;

type ByzzerTableBaseProps = {
    baseColumnWidth?: number;
    autoHeight?: boolean;
    suppressMenu?: boolean;
    wrapText?: boolean;
    headerClassName?: string;
    enableColumnHideShow?: boolean;
}

type ByzzerTableWithSave<TData, TColId> = {
    enableSaveLayout: true;
    columnDefs: ByzColDef<TData, TColId>[]; // see if columnDefs type can be made generic
    tableArea: TableArea;
}

type ByzzerTableWithoutSave<TData> = {
    enableSaveLayout?: false;
    columnDefs: ByzColDef<TData>[] | ColDef<TData>[]; // see if columnDefs type can be made generic
    tableArea?: TableArea;
}

type ByzzerTableProps<TData, TColId, TContext> = (
    ByzzerTableBaseProps
    & (ByzzerTableWithSave<TData, TColId> | ByzzerTableWithoutSave<TData>) 
    & Partial<Omit<AgGridReactProps<TData>, 'columnDefs'>>
);

const ByzzerTableInner = <TData, TColId, TValue, TContext>({
    rowData,
    columnDefs = [],
    baseColumnWidth = 350,
    defaultColDef,
    autoHeight = true,
    suppressMenu = true,
    wrapText = true,
    onCellClicked,
    readOnlyEdit = false,
    onCellEditRequest,
    onFilterChanged,
    onGridReady,
    enableColumnHideShow,
    enableSaveLayout,
    suppressClickEdit,
    singleClickEdit,
    tableArea,
    headerClassName,
    suppressMovableColumns = false,
    ...props
}: ByzzerTableProps<TData, TColId, TContext>, gridRef: React.Ref<AgGridReact<TData>>) => {
    const gridApiRef = useRef<GridApi | null>(null);
    const columnApiRef = useRef<ColumnApi | null>(null);
    const gridReadyRef = useRef<boolean>(false);
    const initialColumnStateRef = useRef<ColumnState[]>();

    const [gridFullyInitialized, setGridFullyInitialized] = useState<boolean>(false); // should only be set to true once the grid is ready and the user's column state has been applied

    const [gridReadyData, setGridReadyData] = useState<GridReadyEvent<TData, TContext>>();
    const [hiddenColumns, setHiddenColumns] = useState<{colId: string, headerName: string}[]>([]);
    
    const {saveTableLayout} = useTenantApi();
    const {refreshUser, user} = useUser();

    useEffect(() => {
        if (gridReadyData) {
            gridReadyRef.current = true;
            if (enableSaveLayout && !gridFullyInitialized) {
                applyUserSavedState(gridReadyData);
            }
            setGridFullyInitialized(true);
        }
    }, [gridReadyData]);

    useEffect(() => {
        if (gridFullyInitialized && gridReadyData) {
            onGridReady?.(gridReadyData);
        }
    }, [gridFullyInitialized]);

    const applyUserSavedState = (gridReadyData: GridReadyEvent<TData, TContext>) => {
        if (!tableArea) return;
        const userLayouts: Record<TableArea, ColumnState[] | null> | undefined = user?.metadata?.tableLayouts;
        const userColumnState = userLayouts?.[tableArea] ?? [];

        if (Boolean(userColumnState) && enableSaveLayout) {
            const applyResult = gridReadyData.columnApi.applyColumnState({state: userColumnState, applyOrder: true});
            if (applyResult) {
                const savedHiddenColumnIds = userColumnState?.filter(c => c.hide)?.map(c => c.colId);
                const savedHiddenColumns: typeof hiddenColumns = savedHiddenColumnIds?.map(hiddenColId => {
                    const headerName = columnDefs?.find(c => c.colId === hiddenColId)?.headerName ?? '';
                    return {
                        colId: hiddenColId,
                        headerName
                    }
                })
                setHiddenColumns(savedHiddenColumns ?? []);
            }
        } else {
            console.warn(`Could not apply columns for tableArea: ${tableArea}. `, {enableSaveLayout, userColumnState: userColumnState});
        }
    }

    const columnDefsMemo = useMemo(() => columnDefs.map(col => {
        let colDef: typeof columnDefs[number];
        if (!col.headerName) {
            colDef = {
                ...col, 
                headerName: '', // overriding default that displays the field name as header name
            }
        } else {
            colDef = {
                ...col,
                ...(enableColumnHideShow && !col?.disableHide ? {
                    headerComponent: CustomHeader,
                    headerComponentParams: {
                        setHiddenColumns,
                        triggerLayoutChange,
                        headerClassName
                    },
                } : {}),
            }
        }
        return colDef;
    }), [columnDefs, enableColumnHideShow]);

    const baseColDef: Partial<ColDef<TData>> = useMemo(() => ({
        width: baseColumnWidth,  // set every column width
        filter: true,  // make every column use 'text' filter by default
        sortable: true,  // enable floating filters by default
        floatingFilter: true,
        resizable: true,
        suppressMenu,
        autoHeight,
        wrapText,
        cellStyle: {
            wordBreak: 'normal'
        },
        lockVisible: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,

        // https://stackoverflow.com/questions/33894190/how-to-wordwrap-a-header-in-ag-grid

    }), [baseColumnWidth, suppressMenu, autoHeight, wrapText, columnDefs, enableColumnHideShow]); // columnDefsMemo

    const handleCellClick = useCallback((event: CellClickedEvent<TData, TValue>) => {
        onCellClicked?.(event);
    }, [onCellClicked, gridFullyInitialized]);

    const handleCellEditRequest = useCallback((event: CellEditRequestEvent<TData, TValue>) => {
        onCellEditRequest?.(event);
    }, [onCellEditRequest, gridFullyInitialized])

    const handleFilterChanges = useCallback((event: FilterChangedEvent<TData, TContext>) => {
        onFilterChanged?.(event);
    }, [onFilterChanged, gridFullyInitialized]);

    const handleGridReady = useCallback((event: GridReadyEvent<TData, TContext>) => {
        gridApiRef.current = event.api;
        columnApiRef.current = event.columnApi;
        initialColumnStateRef.current = event.columnApi.getColumnState();
        setGridReadyData(event);
    }, [onGridReady]);

    const commitTableLayout = async (source?: string, columnState?: ColumnState[]) => {
        const currentColumnState = columnApiRef.current?.getColumnState() ?? null;

        if (enableSaveLayout && Boolean(tableArea)) {
            try {
                saveTableLayout({ tableArea, columnState: columnState ?? currentColumnState });
                refreshUser({
                    ...user,
                    metadata: {
                        ...user?.metadata,
                        tableLayouts: {
                            ...user?.metadata?.tableLayouts,
                            [tableArea!]: source === 'reset' ? null : columnState ?? currentColumnState
                        }
                    }
                });
            } catch (error) {
                debugger
                console.error(`Error saving table layout: ${error}`);
            }
        }
    }

    const debouncedCommitTableLayout = useCallback(debounce(
        async (source?: string, columnState?: ColumnState[]) => {
            if (enableSaveLayout && Boolean(tableArea)) {
                commitTableLayout(source, columnState); // might not need to await?
            }
        },
        LAYOUT_SAVE_DEBOUNCE_TIME_IN_MS
    ), [commitTableLayout]);

    function triggerLayoutChange(source?: string, columnState?: ColumnState[]) {
        if (enableSaveLayout && Boolean(tableArea)) {
            debouncedCommitTableLayout.cancel();
            debouncedCommitTableLayout(source, columnState);
        }
    }

    const handleColumnMoved = useCallback(async (event: ColumnMovedEvent<TData, TContext>) => {
        if (!gridFullyInitialized || !gridReadyRef.current || !event.finished || event.source === 'flex') return;
        triggerLayoutChange();
    }, [gridFullyInitialized]);

    const handleColumnResized = useCallback(async (event: ColumnResizedEvent<TData, TContext>) => {
        if (!gridFullyInitialized || !gridReadyRef.current || !event.finished || event.source === 'flex') return;
        triggerLayoutChange();
    }, [gridFullyInitialized]);

    const handleColumnPinned = useCallback(async (event: ColumnPinnedEvent<TData, TContext>) => {
        if (!gridFullyInitialized || !gridReadyRef.current) return;
        triggerLayoutChange();
    }, [gridFullyInitialized]);

    const handleUnhide = (colIdArg: string) => {
        if (columnApiRef.current) {
            columnApiRef.current.setColumnVisible(colIdArg, true);
            setHiddenColumns((prev) => prev.filter(({colId}) => colId !== colIdArg));
            triggerLayoutChange();
        }
    };

    const handleUnhideAll = () => {
        if (columnApiRef.current) {
            const unhiddenColumnState = columnApiRef.current?.getColumnState().map((col) => {
                return {
                    ...col,
                    hide: false
                };
            });
            columnApiRef.current.applyColumnState({state: unhiddenColumnState, applyOrder: false})
            setHiddenColumns([]);
            triggerLayoutChange();
        }
    }

    const handleReset = () => {
        if (columnApiRef.current) {
            columnApiRef.current.resetColumnState();
            setHiddenColumns([]);
            triggerLayoutChange('reset');
        }
    }

    const columnStatesAreSame = (state1: ColumnState[], state2: ColumnState[]) => {
        const state1Filtered = state1.map(col => {
            const { flex = null, width, ...rest } = col; // maybe pinned too?
            if (suppressMovableColumns || flex) {
                return rest;
            }
            return col;
        });
        const state2Filtered = state2.map(col => {
            const { flex = null, width, ...rest } = col;
            if (suppressMovableColumns || flex) { // dont evaluate flex columns or when columns cant be moved
                return rest;
            }
            return col;
        });

        return (
            state1Filtered.length === state2.length &&
            _.isEqual(state1Filtered, state2Filtered)
        );
    }

    const isDefaultLayout = Boolean(initialColumnStateRef.current) && Boolean(columnApiRef.current?.getColumnState()) && columnStatesAreSame(initialColumnStateRef.current!, columnApiRef.current?.getColumnState()!)
    const showReset = !isDefaultLayout;

    return (
        <div className={classnames(`${baseClassName}__wrapper`)}>
            <AgGridReact<TData>
                className={BYZZER_TABLE_THEME}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefsMemo as any} // Column Defs for Columns
                defaultColDef={defaultColDef ?? baseColDef} // Default Column Properties
                enableCellTextSelection={true}
                tooltipShowDelay={1500}
                // tooltipHideDelay={2500}
                suppressMenuHide={true}
                onCellClicked={handleCellClick} //we probably need this soon in future for further enhancements
                readOnlyEdit={readOnlyEdit}
                onCellEditRequest={handleCellEditRequest}
                singleClickEdit={singleClickEdit}
                suppressClickEdit={suppressClickEdit} //set this true if you only want to have your own way of starting editing, such as clicking a button in your custom cell renderer. setting this true will prevent cell editing
                suppressScrollOnNewData={true}  // tells the grid to NOT scroll to the top when the page changes.
                onGridReady={handleGridReady}
                onColumnMoved={handleColumnMoved}
                onColumnPinned={handleColumnPinned}
                onColumnResized={handleColumnResized}
                // onColumnVisible={handleColumnVisible}
                onFilterChanged={handleFilterChanges}
                suppressMovableColumns={suppressMovableColumns}
                {...props}
            />
            {enableColumnHideShow && (
                <div className={classnames(`${baseClassName}__actions`)}>
                    {Boolean(hiddenColumns.length) ? (
                        <div className={classnames(`${baseClassName}__hidden-columns-container`)}>
                            <p>Hidden columns: click to unhide{hiddenColumns?.length > 1 && <>, or <ByzzerLink onClick={handleUnhideAll}>unhide all</ByzzerLink></>}.</p>
                            <div className={classnames(`${baseClassName}__hidden-columns-list`)}>
                                {hiddenColumns.map(({colId, headerName}) => {
                                    return (
                                        <ByzzerChip
                                            key={colId}
                                            onClick={() => handleUnhide(colId)}
                                        >
                                            {headerName}
                                        </ByzzerChip>
                                    )
                                })}
                            </div>
                        </div>
                    ): <span/>}
                    {showReset && (
                        <div className={classnames(`${baseClassName}__other-actions`)}>
                            <ByzzerButton onClick={handleReset}>Reset columns</ByzzerButton>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export const ByzzerTable = memo(forwardRef(ByzzerTableInner)) as <TData, TColId, TContext>(
    props: ByzzerTableProps<TData, TColId, TContext> & React.RefAttributes<AgGridReact<TData>>
) => JSX.Element;

// @ts-ignore
ByzzerTable.displayName = 'ByzzerTable';
