import './DodHistory.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, IDateFilterParams } from 'ag-grid-community';
import classNames from 'classnames';
import moment from 'moment';
import { alert, confirm, openErrorModal } from '@/components/form/ByzzerModal';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns-tz';
import FavoriteIcon from '@/components/icons/FavoriteIcon';
import { ByzzerTable } from '@/components/ByzzerTable';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { ByzzerMenu } from '@/components/ByzzerMenu';
import { openModifyReportName, openModifySeriesName } from '@/components/DodConfigEditor/common/DodRunNowModal/DodRunNowModal';
import { triggerToast } from '@/notifications/ByzzerToast';
import { useEvents, useUser } from '@/contexts/UserContext';
import { useExtracts } from '@/contexts/ExtractListContext';
import { useTenantApi } from '@/hooks';
import { frameTimePeriod } from '@/services/extract.service';
import { getYearsFromSubscriptionData } from '@/utils/timeperiodUtil';
import { FLOATING_DATE_PICKER_FILTER_PARAMS } from '@/constants/table.constants';
import ReplayIcon from '@images/icons/ReplayIcon.svg';
import useStateRef from 'react-usestateref';
import { getDodSeriesNames } from '@/api';
import { isValidArray } from '@/utils';
import { ByzzerMessagePopUp } from '@byzzer/ui-components';
import { ExtractRun } from '@/types/DataOnDemand';
import { ByzColDef } from '@/types/TableTypes';

const baseClassName = 'dod-history';

type DodHistoryColId = 'isFavorite' | 'reportName' | 'extractStatus' | 'endDtm' | 'timeStamp' | 'runBy' | 'seriesName' | 'timePeriod' | 'categories' | 'actions' | 'id';

const DodHistory = ({ loadSubscription, getScheduledRunsCount, dodTemplatesRef, handleSelectionChange, ...props }) => {
    const {
        getExtracts,
        getExtractSignedUrl,
        deleteExtractReport,
        createUserFavorite,
        deleteUserFavorite,
        deleteTemplate,
        getExtractById
    } = useTenantApi();
    const gridRef = useRef<AgGridReact>(null);
    const navigate = useNavigate();
    const { subscription, user, features } = useUser();
    const restrictManageDoDRun = props.remainingCoreRuns < 1 || !subscription?.active;
    const userRole = user?.role ?? 'user';
    const yrs = getYearsFromSubscriptionData(features?.extendedDataYears);
    const events = useEvents();
    const favorites = useRef<any>();
    // const defaultTimeZone = 'America/New_York';
    const defaultDateFormat = 'yyyy-MM-dd';
    const [loading, setLoading] = useState<boolean>(false);
    const [seriesNames, setSeriesNames, seriesNamesRef] = useStateRef<{
        [key: string]: Set<string>
    }>({});
    const menuRefs = useRef<any[]>([]);
    const {
        extracts: dodExtracts,
        loading: isExtractsLoading,
        fetchExtracts: getDodRecords,
        setExtracts: setDodExtract,
    } = useExtracts();

    const isUserMode = () => {
        return userRole !== 'viewer';
    };
    const fetchSeriesNames = useCallback(async () => {
        try {
            const response = await getDodSeriesNames();
            const individual = isValidArray(response.individualSeriesNames)
                ? new Set(response.individualSeriesNames.map((name) => name.trim().toLowerCase()))
                : new Set([]);
            const scheduled = isValidArray(response.scheduledSeriesNames)
                ? new Set(response.scheduledSeriesNames.map((name) => name.trim().toLowerCase()))
                : new Set([]);
            setSeriesNames({ individual, scheduled });
        } catch (error) {
            console.error('Error fetching DOD series names:', error);
        }
    }, [getDodSeriesNames]);

    useEffect(() => {
        fetchSeriesNames();
    }, []);

    useEffect(() => {
        getDodRecords();
    }, []);

    useEffect(() => {
        if (!events) return;

        async function trackEvent() {
            try {
                const event = events?.[0];
                if (['data_on_demand_update', 'data_on_demand_update_toast'].includes(event?.type)) {
                    const { status, id, endDtm } = event.body;
                    // Update Job Status if row exist
                    const reportIndex = dodExtracts?.findIndex((val) => val.id === id) ?? -1;
                    let extractHistoryTemp = cloneDeep(dodExtracts);
                    if (status === 'Run Completed') {
                        loadSubscription();
                        extractHistoryTemp = await updateRunCompletedRow(id);
                    } else if (status?.toLowerCase()?.includes('failed')) {
                        loadSubscription();
                    } else if (status === 'Running') {
                        getScheduledRunsCount();
                    }
                    if (reportIndex !== -1) {
                        extractHistoryTemp[reportIndex].extractStatus = status;
                        setDodExtract(extractHistoryTemp);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        }

        trackEvent();
    }, [events]);

    const defaultColDef = useMemo(
        () => ({
            filter: true, // make every column use 'text' filter by default
            sortable: true,
            floatingFilter: true, // enable floating filters by default
            resizable: true, //set each col resizable
            width: 200, // set every column width
            flex: 0,
            autoHeight: false,
            wrapText: false,
            suppressMenu: true,
            lockVisible: true
            // suppressMovable:true, to be discussed today?
        }),
        []
    );

    async function handleFavorites(favRecord: any) {
        try {
            const dodRecordId: number = favRecord.id;
            const isfav: boolean = !favRecord.isFavorite;
            let updatedDodRecords = gridRef.current?.props.rowData?.map((obj) => {
                if (obj.id.toString() === favRecord.id || obj.id === dodRecordId) {
                    return { ...obj, isFavorite: !obj.isFavorite };
                }
                return obj;
            });
            setDodExtract(updatedDodRecords as any[]);

            if (isfav) {
                await createUserFavorite('data_on_demand', favRecord.id);
            } else {
                await deleteUserFavorite('data_on_demand', favRecord.id);
            }
        } catch (error: any) {
            console.log(error.message);
        }
    }

    const downloadDod = async (extractId, layoutId, documentType = 'excel') => {
        try {
            setLoading(true);
            const { url } = await getExtractSignedUrl(extractId, layoutId, documentType);
            var link = document.createElement('a');
            link.href = url;
            link.click();
            setLoading(false);
        } catch (err: any) {
            openErrorModal({
                title: 'Download Failed!',
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id
            });
            setLoading(false);
        }
    };

    function onModifyCloneReport(id: number, layoutOnly: boolean = false) {
        const mode = layoutOnly ? 'layout' : 'edit';
        navigate(`/dashboard/extract_editor/${id}?mode=${mode}`);
    }

    function isReportLinkedInTemplates(reportId: number): boolean {
        if(dodTemplatesRef.current.some(template => template.reportId === reportId)) {
            return true;
        } else {
            return false;
        }
    }

    function getLinkedTemplateNameAndIdForRunId(reportId: number) {
        const linkedTemplate = dodTemplatesRef.current.filter(template => template.reportId === reportId)[0];
        return {
            linkedTemplateId: linkedTemplate.id,
            linkedTemplateName: linkedTemplate.displayName
        }
    }

    const onDeleteReport = async (reportId: number) => {
        if (isReportLinkedInTemplates(reportId)) {
            const { linkedTemplateId, linkedTemplateName } = getLinkedTemplateNameAndIdForRunId(reportId);
            if (
                !(await confirm({
                    title: 'Delete Report & Template',
                    content: (
                        <div className="byzzer-allocation-warning">{`This is the original run linked to a template. Deleting this run will delete the template "${linkedTemplateName}".`}</div>
                    ),
                    yesLabel: 'Delete run & template',
                    noLabel: 'Never mind',
                }))
            ) {
                return;
            }
            setLoading(true);
            await deleteTemplate(linkedTemplateId);
            let resp = await deleteExtractReport(reportId);
            if (resp === 'deleted') {
                removeDeletedReport(reportId);
            }
            setLoading(false);
        } else {
            if (
                !(await confirm({
                    title: 'Delete Report',
                    content: <div className="byzzer-allocation-warning">Do you want to delete the selected item ?</div>,
                    yesLabel: 'Yes',
                    noLabel: 'No',
                }))
            ) {
                return;
            }
            setLoading(true);
            let resp = await deleteExtractReport(reportId);
            if (resp === 'deleted') {
                removeDeletedReport(reportId);
            }
            setLoading(false);
        }
    };

    function removeDeletedReport(reportId: number) {
        const reportIndex = dodExtracts?.findIndex((val) => val.id === reportId) ?? -1;
        if (reportIndex !== -1) {
            let extractHistoryTemp = cloneDeep(dodExtracts);
            extractHistoryTemp.splice(reportIndex, 1);
            setDodExtract(extractHistoryTemp);
        }
    }

    const updateRunCompletedRow = async (id: number): Promise<ExtractRun[]> => {
        // we need additional info like download links and timestamps to show in ui table, hence second parameter to getExtractById is true
        const extract = await getExtractById(id, true);
        const updatedExtract = (extract as any).additionalInfo || {};
        if (updatedExtract) {
            updatedExtract.timeStamp =
                updatedExtract?.endDtm && moment(updatedExtract?.endDtm).isValid()
                    ? new Date(updatedExtract?.endDtm).toLocaleTimeString()
                    : 'NA';
            updatedExtract.endDtm =
                updatedExtract?.endDtm && moment(updatedExtract?.endDtm).isValid()
                    ? format(new Date(updatedExtract?.endDtm), defaultDateFormat)
                    : 'NA';
            if (dodExtracts.find((extract) => extract.id === id)) {
                return dodExtracts.map((extract) => (extract.id === id ? updatedExtract : extract));
            }

            return [...dodExtracts, updatedExtract];
        }
        return [];
    };

    var filterParams: IDateFilterParams = {
        includeBlanksInEquals: false,
        includeBlanksInGreaterThan: false,
        includeBlanksInLessThan: false,
        includeBlanksInRange: false
    }
    
    const updateSelectedRows = () => {
        const selectedNodes = gridRef?.current?.api?.getSelectedNodes();
        const selectedData = selectedNodes?.map(node => node.data);
        handleSelectionChange(selectedData);
    };
    
    useEffect(() => {
        if (gridRef.current?.api) {
            gridRef.current?.api?.addEventListener('selectionChanged', updateSelectedRows);
        }
        return () => {
            if (gridRef.current?.api) {
                gridRef.current?.api.removeEventListener('selectionChanged', updateSelectedRows);
            }
        };
    }, [dodExtracts]);
    const [columnDefs] = useState<ByzColDef<ExtractRun, DodHistoryColId>[]>([
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            sortable: false,
            filter: true,
            resizable: false,
            cellRenderer: (item) => {
                return (
                    <FavoriteIcon
                        className={`${baseClassName}__favorites--icon`}
                        trackClick={`Filter by favorites`}
                        selected={item.data?.isFavorite}
                        onClick={() => handleFavorites(item.data)}
                    />
                );
            },
            pinned: 'left',
            colId: 'isFavorite',
            headerName: '',
            width: 100,
            maxWidth: 100,
            minWidth: 100,
            editable: false,
        },
        {
            colId: 'reportName',
            headerName: 'Run Name',
            width: 350,
            pinned: 'left',
            valueGetter: ({ data }) => {
                return `${data?.reportName}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data?.reportName}`;
            },
            disableHide: true,
        },
        {
            colId: 'extractStatus',
            headerName: 'Status',
            valueGetter: ({ data }) => {
                return `${
                    data?.extractStatus === 'Aborted'
                        ? 'Failed'
                        : data?.extractStatus === 'Scheduled Extract'
                        ? 'Scheduled'
                        : data?.extractStatus
                }`;
            },
        },
        ...(window.localStorage['show-dod-id'] === 'true' ? [{ colId: 'id' as const }] : []),
        {
            ...FLOATING_DATE_PICKER_FILTER_PARAMS,
            headerName: 'Run Date',
            colId: 'endDtm',
            tooltipValueGetter: ({ data }) => {
                return `${data?.endDtm}`;
            },
            valueFormatter: ({ data }) => {
                return `${data?.endDtm ?? 'NA'}`;
            },            
            width: 200,
        },
        {
            headerName: 'Timestamp',
            colId: 'timeStamp',
            width: 200,
            valueGetter: ({ data }) => {
                return `${data?.timeStamp}` ;
            },
        },
        {
            colId: 'runBy',
            headerName: 'Run By',
            valueGetter: ({ data }) => {
                return `${data?.user?.firstName} ${data?.user?.lastName}`;
            },
        },
        {
            colId: 'seriesName',
            headerName: 'Series Name',
            width: 350,
            valueGetter: ({ data }) => {
              return `${
                  data.extractInfo?.seriesName
                      ? data.extractInfo?.seriesName
                      : (data.extractInfo?.scheduledInfo?.seriesName ?? "")
              }`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${
                    data.extractInfo?.seriesName
                        ? data.extractInfo?.seriesName
                        : (data.extractInfo?.scheduledInfo?.seriesName ?? "")
                }`;
            },
        },
        {
            colId: 'timePeriod',
            headerName: 'Time Period',
            width: 350,
            valueGetter: ({ data }) => frameTimePeriod(data.ExtractSelection, yrs),
            tooltipValueGetter: ({ data }) => frameTimePeriod(data.ExtractSelection, yrs),
        },
        {
            colId: 'categories',
            headerName: 'Category',
            width: 350,
            valueGetter: ({ data }) => {
                let categories =
                    data?.ExtractSelection?.products?.categories?.selections ??
                    data?.ExtractSelection?.products?.categories;
                categories = categories?.length ? categories?.join(', ') : categories === true ? 'ALL' : '';
                const summedCategories = data?.ExtractSelection?.products?.categories?.summedSelections?.map(sc => `${sc.display} (sum)`);
                return `${categories}${(categories && summedCategories?.length > 0 ? ", " : "")} ${ summedCategories?.join(', ')}`;
            },
            tooltipValueGetter: ({ data }) => {
                let categories =
                    data?.ExtractSelection?.products?.categories?.selections ??
                    data?.ExtractSelection?.products?.categories;
                categories = categories?.length ? categories?.join(', ') : categories === true ? 'ALL' : '';
                const summedCategories = data?.ExtractSelection?.products?.categories?.summedSelections?.map(sc => `${sc.display} (sum)`);
        
                return `${categories}${(categories && summedCategories?.length > 0 ? ", " : "")} ${summedCategories?.join(', ')}`;
            },
        },
        {
            colId: 'actions',
            headerName: 'Actions',
            cellRenderer: ({ data }) => {
                const { id, downloadLinks, reportName, extractInfo } = data;
                const showExcelOption = downloadLinks?.[0]?.format?.includes('excel');
                const showCsvOption = downloadLinks?.[0]?.format?.includes('csv');
                const isDataFilePathExists = data?.downloadLinks?.[0]?.format?.includes('dataFilePath');
                const downloadMenuOptions = [
                    ...(showExcelOption ? [{
                        onClick: () => downloadDod(id, 0),
                        content: <span className={classNames(`${baseClassName}__menu-entry`)}>
                            <i className={classNames(`${baseClassName}__icons`, `${baseClassName}__icons-excel`)}/>{' '}
                            <span>Excel</span>
                        </span>,
                    }] : []), 
                    ...(showCsvOption ? [{
                        onClick: function() {
                            downloadDod(id, 0, 'csv')
                        },
                        content: <span className={classNames(`${baseClassName}__menu-entry`)}>
                            <i className={classNames(`${baseClassName}__icons`, `${baseClassName}__icons-csv`)}/>{' '}
                            <span>CSV</span>
                        </span>,
                    }] : [])
                ];
                const showDownloadMenu = data?.extractStatus === 'Run Completed';
                return (
                    <div className={`${baseClassName}__extract-actions`}>
                        <div className={`${baseClassName}__extract-actions__dropdown`} >
                            {showDownloadMenu && (
                                <div
                                    key={id}
                                    className={classNames(`${baseClassName}__download-menu-trigger`)}
                                    ref={thisElement => menuRefs.current[`${String(id)}-dl`] = {current: thisElement}}
                                />
                            )}
                            <ByzzerMenu
                                className={`${baseClassName}__download-menu`}
                                reference={menuRefs.current[`${String(id)}-dl`]}
                                offset={[-60, -5]}
                                triggerTarget={menuRefs.current[`${String(id)}-dl`]?.current}
                                items={downloadMenuOptions}
                                highlightItemOnHover={true}
                            />
                        </div>
                        <span
                            onClick={() => {
                                onModifyCloneReport(id, false);
                            }}
                        >
                            {isUserMode() && !restrictManageDoDRun && ( 
                                <img
                                    alt={'replay'}
                                    src={ReplayIcon}
                                    className={classNames(`${baseClassName}__icons`)}
                                />
                            )}
                        </span>
                        <div className={`${baseClassName}__extract-actions__dropdown`} >
                            <div 
                                key={id} 
                                className={classNames(`${baseClassName}__menu-trigger`)}
                                ref={thisElement => menuRefs.current[String(id)] = {current: thisElement}}
                            />
                            <ByzzerMenu
                                className={`${baseClassName}__menu`}
                                reference={menuRefs.current[String(id)]}
                                offset={[-90, -5]}
                                triggerTarget={menuRefs.current[String(id)]?.current}
                                highlightItemOnHover={true}
                                items={[
                                    {
                                        onClick: function() {
                                            onDeleteReport(id);
                                        },
                                        content: 'Delete',
                                        disabled: !isUserMode()
                                    }, 
                                    {
                                        onClick: function() {
                                            onModifyCloneReport(id, true);
                                        },
                                        content: 'Apply new layout',
                                        disabled: !isDataFilePathExists
                                    }, 
                                    {
                                        onClick: async function() {
                                            if(isDataFilePathExists && !extractInfo?.scheduledInfo?.seriesName){
                                                try {
                                                    if(data.userId !== user?.id){
                                                        alert({
                                                            content: <>
                                                                <p>You cannot edit this run name because you did not create it.</p>
                                                            </>
                                                        });
                                                        return;
                                                    }
                                                    const updatedReportName = await openModifyReportName(id, reportName);
                                                    if(updatedReportName){
                                                        const reportIndex = dodExtracts?.findIndex((val) => val.id === id) ?? -1;
                                                        if (reportIndex !== -1) {
                                                            let extractHistoryTemp = cloneDeep(dodExtracts);
                                                            extractHistoryTemp[reportIndex].reportName = updatedReportName;
                                                            setDodExtract(extractHistoryTemp);
                                                        } else {
                                                            getDodRecords();
                                                        }
                                                        triggerToast({
                                                            content: 'Run name updated successfully',
                                                        });
                                                    }
                                                } catch(error) {
                                                    alert({
                                                        type: 'error',
                                                        title: 'Run Name Update Failed',
                                                        content: <>
                                                            <p>There was an unexpected error during this run name update.</p>
                                                            <p>Please contact the support team for additional assistance.</p>
                                                        </>
                                                    })
                                                }
                                            }
                                        },
                                        content: <ByzzerMessagePopUp tipDelay={[500, 0]} tip={extractInfo?.scheduledInfo?.seriesName ? "only series name can be renamed for scheduled runs" : ""} tipLocation="top-start" disabled={!extractInfo?.scheduledInfo?.seriesName}>Rename</ByzzerMessagePopUp>,
                                        disabled: Boolean(!isDataFilePathExists || extractInfo?.scheduledInfo?.seriesName),
                                    },
                                    {
                                        onClick: async function() {
                                            if(isDataFilePathExists && (extractInfo.seriesName || extractInfo?.scheduledInfo?.seriesName)){
                                                try {
                                                    if(data.userId !== user?.id){
                                                        alert({
                                                            content: <>
                                                                <p>You cannot edit this series name because you did not create it.</p>
                                                            </>
                                                        });
                                                        return;
                                                    }
                                                    const updatedReportName = await openModifySeriesName(id, (extractInfo.seriesName || extractInfo?.scheduledInfo?.seriesName), extractInfo?.scheduledInfo?.seriesName ? true : false, extractInfo?.scheduledInfo?.seriesName ? seriesNamesRef?.current?.scheduled : seriesNamesRef?.current?.individualSeriesNames);
                                                    if(updatedReportName){
                                                        const reportIndex = dodExtracts?.findIndex((val) => val.id === id) ?? -1;
                                                        if (reportIndex !== -1 && !extractInfo?.scheduledInfo?.seriesName) {
                                                            let extractHistoryTemp = cloneDeep(dodExtracts);
                                                            extractHistoryTemp[reportIndex].extractInfo.seriesName = updatedReportName;
                                                            setDodExtract(extractHistoryTemp);
                                                        } else {
                                                            getDodRecords();
                                                        }
                                                        triggerToast({
                                                            content: 'series name updated successfully',
                                                        });
                                                    }
                                                } catch(error) {
                                                    alert({
                                                        type: 'error',
                                                        title: 'Series Name Update Failed',
                                                        content: <>
                                                            <p>There was an unexpected error during this series name update.</p>
                                                            <p>Please contact the support team for additional assistance.</p>
                                                        </>
                                                    })
                                                }
                                            }
                                        },
                                        content: 'Rename Series',
                                        disabled: !isDataFilePathExists || !(extractInfo.seriesName || extractInfo?.scheduledInfo?.seriesName)
                                    }
                                ]}
                            />
                        </div>
                    </div>
                );
            },
            pinned: 'right',
            filter: false,
            floatingFilter: false,
            resizable: false,
            lockPosition: 'right',
            sortable: false,
            width: 170,
            disableHide: true,
            // maxWidth: 170,
            // minWidth: 170,
        },
    ]);

    return (
        <>
            <ByzzerMask show={loading || isExtractsLoading} loading={loading || isExtractsLoading}>
                Loading your Data On Demand runs
            </ByzzerMask>
            <ByzzerTable
                ref={gridRef} // Ref for accessing Grid's API
                rowData={dodExtracts} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Column Defs for Columns
                readOnlyEdit={false}
                singleClickEdit={false}
                rowSelection='multiple'
                suppressRowClickSelection={true}
                tableArea={'dodHistory'}
                enableColumnHideShow={true}
                enableSaveLayout={true}
                overlayNoRowsTemplate='No Data On Demand runs yet'
            />
        </>
    );
};

DodHistory.propTypes = {};

export default DodHistory;
