import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './AboutYourPortfolio.scss';
import { ByzzerButton, WizardActions, WizardContent, WizardHeader, WizardStep } from "@byzzer/ui-components";
import { DefaultsConfigWizard } from "@/components/UserDefaultsAndPreferences/DefaultsEditor/DefaultsConfigWizard";
import { DefaultConfigValue } from "@/components/UserDefaultsAndPreferences/DefaultsEditor/DefaultsConfigWizard/DefaultsConfigWizardContext";
import { useTenantApi } from "@/hooks";
import { useUser } from "@/contexts/UserContext";
import { DefaultRunConfig } from "@/types/ApiTypes";
import { DEFAULT_FOCUSKPI } from "@/constants/userPreferences.constants";
import { OnboardingStepProps } from "../../OnboardingWizard";
import { MarketRunConfigOptions, ProductRunConfigOptions, TimeRunPeriodConfigOptions } from '@/types/RunConfigOptions';
import { SelectorLabelInfo } from "@/components/SelectorLabelInfo";

export const defaultConfigOptions = [
    {
        type: 'product',
        title: 'Focus Product(s)',
        includeFocusBrands: true,
        includeCategories: true,
        maxCharacteristics: 5,
    } as Partial<ProductRunConfigOptions>,
    {
        type: 'market',
        maxMarkets: 1,
        title: 'Focus Market',
        includeTotalUSMarkets: true,
        includeFmcgRetailers: true,
        includeSpecialityRetailers: true,
        includeGeographyMarkets: true,
    } as Partial<MarketRunConfigOptions>,
    {
        type: 'time_period',
        title: 'Time Period',
    } as Partial<TimeRunPeriodConfigOptions>,
];

export type DefaultConfigLabels = Record<'focusBrands' | 'categories' | 'markets_header' | 'time_period_header', ReactNode>;

const defaultConfigWizardLabels: DefaultConfigLabels = {
    focusBrands: <span><b>Which brand (or brands) do you manage?</b></span>,
    categories: <span><b>Which category(s) within your company's portfolio do you manage?</b></span>,
    markets_header: <span><b>Which market are you primarily focused on?</b>  If you cover many retailers, consider selecting the most appropriate total market for your overall reporting.</span>,
    time_period_header: <span><b>Which period of time do you typically want to analyze?</b></span>,
}

export const AboutYourPortfolio = forwardRef(({
    action,
    isLastStep,
    step,
    onNext,
    onSkip,
    setBusy,
    title,
    nextText,
    busy,
    code,
    ...props
}: OnboardingStepProps, ref) => {
    const stepRef = useRef<any>();
    const baseClassName = 'byz-onboarding-about-your-portfolio';
    const defaultsRef = useRef<NonNullable<DefaultConfigValue>>();
    const [shouldSaveData, setShouldSaveData] = useState(false);
    const {saveUserDefaultRunConfig} = useTenantApi();
    const {brands, categories, refreshDefaultRunConfig, allProducts} = useUser();
    const [preferencePreset, setPreferencePreset] = useState<Partial<DefaultRunConfig>>();
    // this is required to allow multiple refs to the step.  needs the dependency array or will cause infinite loop
    useImperativeHandle(ref, () => stepRef.current, []);

    const productLabelOptions = allProducts?.find((product) => product.sku === 'onboarding_wizard')?.labelOption?.filter((labelOption) => labelOption.stepCode === code && Boolean(labelOption.componentCode));

    const productLabelOptionsMap = Object.fromEntries(productLabelOptions?.map((labelOption) => [labelOption.componentCode!, labelOption.label]) ?? []);

    const labelsAndTips = Object.fromEntries(Object.entries(defaultConfigWizardLabels).map(([key, value]) => {
        return [key, <SelectorLabelInfo label={productLabelOptionsMap[key]} sku={'onboarding_wizard'} defaultLabel={value}/>]
    }));

    useEffect(() => {
        ;(async () => {
            if (shouldSaveData && defaultsRef.current) {
                setBusy?.(true);
                const saveResult = await saveUserDefaultRunConfig(defaultsRef.current)
                refreshDefaultRunConfig(saveResult);
                onNext();
            }
            setShouldSaveData(false);
        })()
    }, [shouldSaveData]);


    function handleCancel() {
    }

    function handleComplete() {
    }

    function handleNext(id?: string) {
        setShouldSaveData(true);
        return false;
    }

    async function handleSkip() {
        setBusy?.(true);
        onSkip();
    };
    
    useEffect(() => {
        if (brands?.length || categories?.length) {
            setPreferencePreset({
                brands: brands ?? [],
                categories: categories ?? [],
                focusKPI: DEFAULT_FOCUSKPI
            })
        }
    }, [brands, categories]);

    return (
        <WizardStep className={classnames(baseClassName)} byzRef={stepRef} title={title} id={step}>
            <WizardHeader className={classnames({
                // 'report-run-config-wizard-header--valid': filtersValid
            })}>
                <h1 className={`report-run-config-wizard__step-title`}>
                    {title}
                </h1>
            </WizardHeader>
            <WizardContent>
                <DefaultsConfigWizard 
                    onComplete={handleComplete} 
                    onCancel={handleCancel} 
                    ref={defaultsRef} 
                    defaultPreset={preferencePreset}
                    //  @ts-ignore
                    defaultConfigOptions={defaultConfigOptions} 
                    labelsAndTips={labelsAndTips as typeof defaultConfigWizardLabels}
                    code={code}
                />
            </WizardContent>
            <WizardActions 
                disableNext={false} 
                nextDisabledTip={'You must choose all required values to continue.'} 
                beforeNext={handleNext}
                nextText={nextText}
            >
                <ByzzerButton
                    className={`${baseClassName}__skip-btn`}
                    type="negative"
                    onClick={handleSkip}
                    label={"Skip for now"}
                />
            </WizardActions>
        </WizardStep>
    );
});

export default AboutYourPortfolio;

AboutYourPortfolio.displayName = 'AboutYourPortfolio';

