import './SelectorLabelInfo.scss';
import { ByzzerTipIcon } from '@byzzer/ui-components';
import { OptionalRequired, OptionalRequiredProps } from '../OptionalRequired';
import { useUser } from '@/contexts/UserContext';
import { defaultSelectorLabels } from '@/constants/defaultLabels.constants';
import { ReactNode } from 'react';

type SelectorLabelProps = {
    required?: boolean;
    includeSuffix?: boolean;
    optionalSuffix?: string;
    showLabel?: boolean;
    showTipInfo?: boolean;
    max?: number;
    min?: number;
    selectorCode?: string;
    sku: string;
    tipDelay?: number | [number | null, number | null];
    isLabelBold?: boolean;
    defaultLabel?: ReactNode;
    stepCode?: string;
} & OptionalRequiredProps;

export function SelectorLabelInfo({
    required,
    includeSuffix,
    optionalSuffix,
    showLabel = true,
    showTipInfo = true,
    max,
    min,
    selectorCode,
    sku,
    tipDelay,
    isLabelBold = false,
    defaultValue,
    defaultLabel = defaultValue,
    ...props
}: SelectorLabelProps) {
    const baseClassName = 'selector-label-info';

    const {allProducts} = useUser();

    // const runConfigOptions:RunConfigOptions[] = getRunConfigOptionsBySku(sku);  , may need this in future

    const MIN_HOLDER: string = '${minValue}';
    const MAX_HOLDER: string = '${maxValue}';

    let productLabelOptions = allProducts?.find((product) => product.sku === sku)?.labelOption;

    let label = productLabelOptions?.find((labelOption) => labelOption.labelCode === selectorCode)?.label;
    let toolTipInfo = productLabelOptions?.find((labelOption) => labelOption.labelCode === selectorCode)?.tip;
    //TODO: handle the undefined and null response as well for labels and tips in MVP-2
    /**
     * @param selectorResponse - for labels, it will be either populated from alby or undefined
     * and for tooltip, it will be null from api if not set on ALby or undefined
     * @returns string
     */
    function handleSelectorDynamicValue(selectorResponse: string) {
        let selectorlabelInfo: string = selectorResponse;

        if (selectorResponse?.includes(MIN_HOLDER)) {
            selectorlabelInfo = selectorResponse.replace(MIN_HOLDER, Number(min).toString());
        }
        if (selectorResponse?.includes(MAX_HOLDER)) {
            selectorlabelInfo = selectorResponse.replace(MAX_HOLDER, Number(max).toString());
        }

        return selectorlabelInfo;
    }
    //CHECKS to replace the empty string with the default value, else render the values set on alby for labels
    if (showLabel && label) {
        label = handleSelectorDynamicValue(label);
        if (!label?.replace(/\s/g, '').length) {
            label = defaultSelectorLabels?.[`${selectorCode}`]?.label;
        }
    } else {
        label = '';
    }

    let defaultToolTipInfo: ReactNode | string;
    //CHECKS to replace the empty string with the default value, else render the values set on alby for info
    if (showTipInfo && toolTipInfo) {
        const getToolTipInfo = handleSelectorDynamicValue(toolTipInfo);
        if (!getToolTipInfo?.replace(/\s/g, '').length) {
            defaultToolTipInfo = defaultSelectorLabels?.[`${selectorCode}`]?.tip;
        }
    }

    /*
     * the component will render the label of the selector, reuired/optional , tipinfo based on alby settings, if not found will render a default value from FE
     */
    return (
        <>
            <span className={baseClassName}>
                <OptionalRequired
                    required={required}
                    label={label}
                    includeSuffix={includeSuffix}
                    optionalSuffix={optionalSuffix}
                    isLabelBold = {isLabelBold}
                    defaultLabel={defaultLabel}
                    {...props}
                />
                {/* todo: add a fix to combine defaultToolTipInfo & toolTipInfo logic. Also check if showTipInfo as true is really required */}
                {defaultToolTipInfo && !toolTipInfo &&
                    <ByzzerTipIcon tip={defaultToolTipInfo} tipDelay={tipDelay}/>
                }
                {toolTipInfo &&
                     <ByzzerTipIcon tipMarkdown={toolTipInfo} tipDelay={tipDelay}/>
                }
            </span>
        </>
    );
}
