import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './AboutYourCompany.scss';
import { ByzzerButton, ByzzerCheckableChangeEvent, ByzzerCheckbox, WizardActions, WizardContent, WizardHeader, WizardStep } from "@byzzer/ui-components";
import { ByzzerBrandSearch } from "@/components/ByzzerBrandSearch";
import { openChat } from '@/utils';
import { TipIcon } from "@/components/icons";
import { ByzzerLink, ByzzerModal } from "@/components/form";
import { CategorySelectForTopBrands } from "@/components/CategorySelectForTopBrands";
import CategorySelector from "@/views/onboarding/CategorySelector";
import { useTenantApi } from "@/hooks";
import { useUser } from "@/contexts/UserContext";
import { confirm } from '@/components/form';
import { OnboardingStepProps } from "../../OnboardingWizard";
import { SelectorLabelInfo } from "@/components/SelectorLabelInfo";

export type CategoryOptionsForSelectedBrandsRef = {
    hasCategoryOptions: boolean;
    categorySearchIsComplete?: boolean | undefined;
    categoryOptionsCount?: number | undefined;
};

const baseClassName = 'byz-onboarding-about-your-company';

export const AboutYourCompany = forwardRef(({
    action,
    isLastStep,
    step,
    onNext,
    onSkip,
    setBusy,
    title,
    nextText,
    busy,
    ...props
}: OnboardingStepProps, ref) => {
    const { getMySubscriptionUsage, createTenantCompanyProductConfig, saveFreeUserDefaultRunConfig } = useTenantApi();
    const stepRef = useRef<any>();
    const [brandNotRetailingYet, setBrandNotRetailingYet] = useState<boolean>(false);
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [maxCategories, setMaxCategories] = useState<number>(1);
    const [advancedCategorySelections, setAdvancedCategorySelections] = useState<string[]>([]);
    const [showCategory, setShowCategory] = useState<boolean>(false);
    const [showBrandTips, setShowBrandTips] = useState<boolean>(false);
    const [shouldSaveData, setShouldSaveData] = useState(false);
    // this is required to allow multiple refs to the step.  needs the dependency array or will cause infinite loop
    useImperativeHandle(ref, () => stepRef.current, []);

    const categoryOptionsForTopBrandsRef = useRef<CategoryOptionsForSelectedBrandsRef>(null);

    const { categories, refreshCompanyBrandInfo, subscription, hierarchyTree, refreshDefaultRunConfig } = useUser();

    const { hasCategoryOptions, categorySearchIsComplete, categoryOptionsCount } = categoryOptionsForTopBrandsRef?.current ?? {};

    useEffect(() => {
        getCategoryMax();
    }, []);

    useEffect(() => {
        setAdvancedCategorySelections(selectedCategories);
    }, [selectedCategories])

    useEffect(() => {
        ;(async () => {
            if (shouldSaveData && await handleSubmitConfirmModal()) {
                onNext();
            }
            setShouldSaveData(false);
        })()
    }, [shouldSaveData]);

    function handleBrandNotRetailingCheck({checked}: ByzzerCheckableChangeEvent<string>) {
        setBrandNotRetailingYet(checked)
    }

    function handleBrandChange(e: ByzzerChangeEvent<string[]>) {
        const newSelectedBrand = e.value;
        setSelectedBrands(newSelectedBrand);
    }

    async function getCategoryMax() {
        setBusy?.(true)
        try {
            const { categories: categoriesUsage } = await getMySubscriptionUsage();
            setMaxCategories(categoriesUsage?.limit);
        } catch (err) {
            console.error(`AboutYourCompany - getCategoryMax error`, err);
        } finally {
            setBusy?.(false)
        }
    }

    const handleSubmit = async () => {
        setBusy?.(true)
        try {

            // const { settings: {brandNotRetailingYet: updatedBrandNotRetailingYet} } = await updateTenantCompanySettings<boolean>('brandNotRetailingYet', brandNotRetailingYet);
            // const { brands: updatedBrands } = await updateTenantBrands(brandNotRetailingYet ? [] : selectedBrands);
            const newProductConfig = await createTenantCompanyProductConfig({
                categories: selectedCategories,
                brandNotRetailingYet,
                initialBrands: !brandNotRetailingYet && selectedBrands?.length ? selectedBrands : [],
            });
            if (subscription?.metadata?.isFree && Boolean(selectedCategories?.length)) {
                const saveResult = await saveFreeUserDefaultRunConfig({
                    selectedBrands,
                    selectedCategories,
                    brandNotRetailingYet,
                })
                refreshDefaultRunConfig(saveResult)
            }
            // await onComplete?.('OnboardingCategories'); 
            refreshCompanyBrandInfo({
                brands: newProductConfig?.brands,
                brandNotRetailingYet: newProductConfig?.brandNotRetailingYet,
                categories: newProductConfig?.categories,
            });
        } catch (err) {
            console.error(`AboutYourCompany - handleSubmitClick error`, err);
            // todo: handle error
        } finally {
        }
    }

    function searchOptionsFooter() {
        return (
            <div className={`${baseClassName}__brandSearch-footer`}>
                <hr />
                <p><b>Having trouble finding your brand?  We can help!</b></p>
                <p className={classnames(`${baseClassName}__brandSearch-link`)} onClick={() => setShowCategory(!showCategory)}>Select category(s) without using your brand.</p> {/* <b>(</b><i>Search Other Categories</i><b> )</b> */}
                <p className={classnames(`${baseClassName}__brandSearch-link`)} onClick={openChat}>Chat with Customer Support {/** Open the chat box modal */}</p>
            </div>
        )
    }

    function openConfirmation() {
        // setOpen(true);
    };

    function handleCategorySelectionChange(categories: string[]) {
        setAdvancedCategorySelections(categories)
    }

    const handleAdvancedSearchSaveExit = () => {
        setShowCategory(!showCategory)
        setSelectedCategories(advancedCategorySelections);
    }

    const categorySelectorModalFooter = (
        <div className={`${baseClassName}__advanced-search-footer`}>
            <div className={`${baseClassName}__advanced-search-save`}>
                <ByzzerButton
                    onClick={handleAdvancedSearchSaveExit}
                    label="Save & Close"
                />
            </div>
        </div>
    )

    function handleNext(id?: string) {
        setShouldSaveData(true);
        return false;
    }

    const handleSkip = async () => {
        setBusy?.(true);
        onSkip();
    };

    const handleSubmitConfirmModal = async () => {
        const selectedCategoriesCount = selectedCategories?.length;
        if (Boolean(selectedCategoriesCount) && selectedCategoriesCount < maxCategories) {
            if (
                await confirm({
                    title: '',
                    content: <>
                        {Boolean(selectedCategoriesCount) && selectedCategoriesCount < maxCategories && (
                            `You have ${maxCategories - selectedCategoriesCount} remaining category(s) available in your subscription. You can go to the Subscription page to select more categories for your account.`
                        )}
                        {!selectedCategoriesCount && (
                            `You have not picked any categories but your subscription includes ${maxCategories}. You can go to the Subscription page to select categories for your account.`
                        )}
                    </>,
                    noLabel: 'Cancel',
                    yesLabel: 'Continue',
                })
            ) {
                setBusy?.(true);
                await handleSubmit()
                return true;
            } else {
                return false;
            }
        } else {
            setBusy?.(true);
            await handleSubmit()
            return true;
        }
    }

    function handleCategoriesChange(e: ByzzerChangeEvent<string[]>) {
        const newSelectedCategories = e.value;
        setSelectedCategories(newSelectedCategories);
    }

    const placeholderTextForCategorySelectForTopBrands = Boolean(selectedBrands?.length) && hasCategoryOptions && categorySearchIsComplete ? 'Select your category(s)' : '';

    const disableCategorySelectForTopBrands: boolean = Boolean(!selectedCategories?.length && (!selectedBrands?.length || (selectedBrands?.length && categorySearchIsComplete && !hasCategoryOptions)));

    return (
        <WizardStep className={classnames(baseClassName)} byzRef={stepRef} title={title} id={step}>
            <WizardHeader className={classnames({
                // 'report-run-config-wizard-header--valid': filtersValid
            })}>
                <h1 className={`report-run-config-wizard__step-title`}>
                    {title}
                </h1>
            </WizardHeader>
            <WizardContent>

                <div className={`${baseClassName}`}>
                    
                    <div className={classnames(`${baseClassName}__section`, `${baseClassName}__section-brands`)}>
                        <SelectorLabelInfo
                            sku={'onboarding_wizard'}
                            selectorCode={`${step}-brandSelector`}
                            defaultLabel={
                                <div className={`${baseClassName}__heading`}>
                                    What are all the brands under your company's portfolio?
                                </div>
                            }
                        />

                        <div className={`${baseClassName}__brand-selections`}>
                            <ByzzerCheckbox
                                onChange={handleBrandNotRetailingCheck}
                                disabled={false}
                                checked={brandNotRetailingYet}
                                label={"My brand isn't selling in retailers yet."}
                            />
                            <ByzzerBrandSearch
                                name={'focusBrands'}
                                maxSelections={5} // todo: see if there is a max for this
                                value={selectedBrands}
                                onChange={handleBrandChange}
                                // label={'Brand'}
                                searchOptionsFooter={searchOptionsFooter}
                                allowSearchOfAllBrands={true}
                                onlyShowSearchOptionsFooterWithSearchResults={false}
                                placeholder={!brandNotRetailingYet ? 'Search for your brand(s)' : 'Search for similar brands so we can look up your category(s)'}
                            />
                        </div>
                    </div>

                    <div className={classnames(`${baseClassName}__section`, `${baseClassName}__section-categories`)}>
                        <div className={`${baseClassName}__heading`}>
                            <SelectorLabelInfo 
                                sku={'onboarding_wizard'}
                                selectorCode={`${step}-categorySelector`} 
                                defaultLabel={
                                    <>
                                        <div className={`${baseClassName}__heading`}>
                                            Choose the categories you want to license in your subscription.
                                        </div>
                                        <p>This is required to run any Byzzer report.</p>
                                    </>
                                }
                            />
                        </div>
                        <div className={`${baseClassName}__categories-selections`}>
                            <div>
                                <span className={classnames(`${baseClassName}__text-bold`)}>
                                    Your subscription includes {busy || maxCategories === 0 ? '-' : maxCategories} category(s).
                                </span>
                                <span className={classnames(`${baseClassName}__text`)}>
                                    &nbsp; We found the following category(s) selling your brands, sorted from highest to lowest $ sales.
                                </span>
                            </div>
                            {Number(categoryOptionsCount) > maxCategories && (
                                <p className={classnames(`${baseClassName}__categories-text`)}>
                                    We have pre-selected the top {maxCategories} of your {categoryOptionsForTopBrandsRef?.current?.categoryOptionsCount} categories.
                                </p>
                            )}
                            <div className={classnames(`${baseClassName}__categories-selector-container`)}>
                                {/* <div className={classnames(`${baseClassName}__categories-selector-wrapper`)}> */}
                                    <CategorySelectForTopBrands
                                        name={'topCategories'}
                                        value={selectedCategories}
                                        onChange={handleCategoriesChange}
                                        placeholder={placeholderTextForCategorySelectForTopBrands}
                                        disabled={disableCategorySelectForTopBrands}
                                        allowClear={!busy}
                                        brands={selectedBrands}
                                        maxSelections={maxCategories}
                                        ref={categoryOptionsForTopBrandsRef}
                                    />
                                {/* </div> */}
                                <ByzzerButton
                                    className={classnames(`${baseClassName}__categories-selector-search-button`)}
                                    type="negative"
                                    onClick={() => setShowCategory(!showCategory)}
                                    label={"Search Other Categories"}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ByzzerModal
                    show={showCategory}
                    heading={'Select Your Category(s)'}
                    onClose={() => setShowCategory(false)}
                    size={'large'}
                    headerType={'normal'}
                    footerContent={categorySelectorModalFooter}
                    className="byzzer-model-position" // todo: see if spelling can be fixed
                >
                    <section className={classnames(`${baseClassName}__advanced-search-modal`)}>
                        <div className={classnames(`${baseClassName}__advanced-search-info`)}>
                            <span className={classnames(`${baseClassName}__text-bold`)}>Your subscription includes {busy ? '?' : maxCategories} category(s).</span>
                        </div>
                        {showCategory && (
                            // @ts-ignore
                            <CategorySelector
                                busy={busy}
                                maxCategoryCount={maxCategories}
                                onSelectionChange={handleCategorySelectionChange}
                                categories={advancedCategorySelections}
                                searchByHierarchy={true}
                            />
                        )}
                    </section>
                </ByzzerModal>

            </WizardContent>
            <WizardActions 
                disableNext={!selectedCategories.length || busy} 
                nextDisabledTip={'You must choose all required values to continue.'} 
                // @ts-ignore
                beforeNext={handleNext} 
                nextText={nextText}
            >
                <ByzzerButton
                    className={`${baseClassName}__skip-btn`}
                    type="negative"
                    onClick={handleSkip}
                    label={"Skip for now"}
                />
            </WizardActions>
        </WizardStep>
    );

});

export default AboutYourCompany;

AboutYourCompany.displayName = 'AboutYourCompany';

