import React, { useEffect } from 'react';
import { ColDef, ColumnState, IHeaderParams } from 'ag-grid-community';
import classnames from 'classnames';
import './CustomHeader.scss'

interface CustomHeaderParams extends IHeaderParams {
    setHiddenColumns: React.Dispatch<React.SetStateAction<{ colId: string; headerName: string }[]>>;
    triggerLayoutChange: (source?: string) => void;
    headerClassName?: string;
}

interface CustomColDef extends ColDef {
    isHidden?: boolean;
}

const baseClassName = 'byz-custom-header';

export const CustomHeader = ({
    displayName,
    enableSorting,
    progressSort,
    column,
    columnApi,
    setHiddenColumns,
    triggerLayoutChange,
    headerClassName,
    ...props
}: CustomHeaderParams) => {
    const colDef = column.getColDef() as CustomColDef;

    const onSortRequested = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (enableSorting) {
            progressSort(event.shiftKey);
        }
    };

    const handleHideClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();

        if (!colDef) return;

        columnApi.setColumnVisible(column, false);

        setHiddenColumns((prev) => [
            ...prev,
            { colId: column.getColId(), headerName: column.getColDef().headerName ?? '???' },
        ]);
        triggerLayoutChange();
    };

    return (
        <div className={classnames(`${baseClassName}`)}>
            <span
                className={classnames(`${baseClassName}__label`, headerClassName)}
                onClick={onSortRequested}
                style={{ cursor: enableSorting ? 'pointer' : 'default' }}
            >
                {displayName}
            </span>
            <div className={classnames(`${baseClassName}__actions`)}>
                <span className={classnames(`${baseClassName}__hide`)} onClick={handleHideClick}>
                    Hide {/* todo: replace with eye icon */}
                </span>
            </div>
        </div>
    );
};

export default CustomHeader;
