import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './ByzzerChip.scss';

export type ByzzerChipProps = {
    className?: string;
    children?: ReactNode;
    label?: ReactNode;
    onClick?: () => void;
}


export const ByzzerChip = ({
    label,
    children = label,
    className,
    onClick,
    ...props
}: ByzzerChipProps) => {

    const baseClassName = 'byzzer-chip';

    function handleClick() {
        onClick?.();
    }

    const isClickable = Boolean(onClick);

    return (
        <div className={classnames(baseClassName, className, {
            [`${baseClassName}--clickable`]: isClickable
        })} onClick={handleClick}>
            {typeof children === 'string' ? (
                <span className={classnames(`${baseClassName}__text-content`)}>
                    <span>{children}</span> 
                    <span className={classnames(`${baseClassName}__text-content-clear`)}>{isClickable ? ' x' : ''}</span>
                </span> 
            ) : children}
        </div>
    );

};

export default ByzzerChip;

ByzzerChip.displayName = 'ByzzerChip';